import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import React, { ReactElement } from "react";

import Chrome from "../src/components/Chrome";
import CustomError from "../src/components/CustomError";

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <NextSeo
        title={t("Page Not Found")}
        canonical="https://findahelpline.com/error"
        noindex={true}
      />
      <Chrome footer>
        <CustomError
          heading={t("Resource Not Found")}
          message={t(
            "We have searched high and low but cannot find the content you're looking for.",
          )}
        />
      </Chrome>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale, ["common"])),
    },
    revalidate: 60 * 60 * 24, // 24 hours
  };
};

export default PageNotFound;
