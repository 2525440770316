import InfoIcon from "@mui/icons-material/InfoRounded";
import { Box, Container, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import React, { ReactElement } from "react";

import LinkText from "../LinkText";

type Props = {
  heading?: string;
  message?: string;
};

const CustomError = ({ heading, message }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Box my={2}>
        <Typography
          variant="h6"
          component="h1"
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily,
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {heading ? heading : t("Sorry, something went wrong 😞")}
        </Typography>
      </Box>
      <Box mt={2}>
        <Container
          maxWidth="xs"
          sx={{
            display: "grid",
            borderRadius: "10px",
            gap: 2,
            textAlign: "left",
            py: 2,
            px: 3,
            height: "calc(100% - 2px)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            backgroundColor: "background.paper",
          }}
        >
          <InfoIcon sx={{ justifySelf: "center" }} color="secondary" />
          <Typography gutterBottom>
            {message ?
              message
            : <Trans t={t}>
                It looks like something has gone wrong with the page. The
                problem has been logged for our team to look at. In the
                meantime, you might like to try refreshing the page or
                navigating to another location.
              </Trans>
            }
          </Typography>
          <Typography gutterBottom>
            <Trans t={t}>
              If you are experiencing an emotional crisis and want someone to
              talk to, search for your country and the topic you would like help
              with{" "}
              <LinkText underline href="/">
                here
              </LinkText>
              . If you’re not ready to talk, here are some other options:
            </Trans>
          </Typography>
          <Typography gutterBottom component="ul">
            <li>
              <Trans t={t}>
                <Link
                  href="https://nowmattersnow.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "underline",
                    color: "text.primary",
                  }}
                >
                  Watch videos
                </Link>{" "}
                about coping with difficult feelings (via Now Matters Now)
              </Trans>
            </li>
            <li>
              <Trans t={t}>
                <Link
                  href="https://www.mysafetyplan.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "underline",
                    color: "text.primary",
                  }}
                >
                  Make a safety plan
                </Link>{" "}
                (via Vibrant Emotional Health)
              </Trans>
            </li>
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default CustomError;
